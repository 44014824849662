<script>

import Tabulation from "@/view/tabulate/Tabulate.vue";
import SearchCombination from "@/view/search/SearchCombination.vue";

export default {
  components: {SearchCombination, Tabulation},
  data(){
    return {
      tableData: [],
      pageSize: 20,
      pageNum: 1,
      total: 0,
      search: ""
    }
  },
  created() {
    this.getTableList()
  },
  methods: {
    /**
     * 获取订单列表
     * @param data
     * @param mod
     * @param pageNum
     */
    getTableList(data = null, mod = null, pageNum = null){
      this.axios.get(this.$api.publicUrl.ReplacePage, {
        params: {
          pageSize: this.pageSize,
          pageNum: pageNum || this.pageNum,
          ...data
        }
      }).then(res =>{
        if(res.data.status === 200){
          this.tableData = res.data.data.list
          this.total = res.data.data.total
          if(mod){
            this.$message.success("查询成功！")
          }
        }else {
          this.$message(res.data.data.message)
        }
      })
    },
    /**
     * 搜索组件的回调
     * @param data {Object} 搜索的条件数据
     */
    searchCallBack(data){
      this.getTableList(data, true, 1)
    },
    /**
     * 跳转
     * @param data
     */
    jumpOrderInfo(data){
      this.$store.commit("updateItem", 0)
      this.$router.push({
        path: "/order/order_all",
        query: {
          id: data.orderId
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getTableList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getTableList()
    }
  }
}
</script>

<template>
  <fragment v-if="tableData">
    <h1 class="title">请输入查询条件</h1>
    <section class="search">
      <SearchCombination :data="{
        list: [
          [
            {ti: '客户名称', type: 'input', key: 'userName', class: 'min'},
            {ti: '司机名称', type: 'input', key: 'operatorName', class: 'min'},
            {ti: '车辆信息', type: 'input', key: 'carInfo', class: 'min'},
          ]
        ],
        senior: false
      }" @searchCallBack="searchCallBack"></SearchCombination>
    </section>
    <section class="control">
      <div class="con_ti">换车记录 <span>{{this.total}}</span> 条</div>
    </section>
    <section class="tabulation" v-if="tableData">
      <Tabulation :data="{
        columnName: [
          {name: '订单ID', prop: 'orderId', type: 'text', needSort: false},
          {name: '客户姓名', prop: 'userName', type: 'text', needSort: false},
          {name: '更换前', prop: 'carInfoBefore', type: 'text', needSort: false, style: 'flex: 1.5'},
          {name: '更换后', prop: 'carInfoAfter', type: 'text', needSort: false, style: 'min-width: 80px'},
          {name: '司机', prop: 'operatorName', type: 'text', needSort: false},
          {name: '更换时间', prop: 'carChangeTime', type: 'text', needSort: false, style: 'min-width: 180px'},
        ],
        rows: this.tableData,
        control: [
          {name: '查看详情', type: 'warning', method: this.jumpOrderInfo},
        ]
      }"></Tabulation>
    </section>
    <section class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="this.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.total">
      </el-pagination>
    </section>
  </fragment>
  <fragment v-else>
    <img src="../../../assets/images/loading.gif" class="loading" height="100" width="100" alt="加载中"/></fragment>
</template>
<style src="@/assets/css/componentStyle.css" scoped></style>
