var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tableData)?_c('fragment',[_c('h1',{staticClass:"title"},[_vm._v("请输入查询条件")]),_c('section',{staticClass:"search"},[_c('SearchCombination',{attrs:{"data":{
      list: [
        [
          {ti: '客户名称', type: 'input', key: 'userName', class: 'min'},
          {ti: '司机名称', type: 'input', key: 'operatorName', class: 'min'},
          {ti: '车辆信息', type: 'input', key: 'carInfo', class: 'min'},
        ]
      ],
      senior: false
    }},on:{"searchCallBack":_vm.searchCallBack}})],1),_c('section',{staticClass:"control"},[_c('div',{staticClass:"con_ti"},[_vm._v("换车记录 "),_c('span',[_vm._v(_vm._s(this.total))]),_vm._v(" 条")])]),(_vm.tableData)?_c('section',{staticClass:"tabulation"},[_c('Tabulation',{attrs:{"data":{
      columnName: [
        {name: '订单ID', prop: 'orderId', type: 'text', needSort: false},
        {name: '客户姓名', prop: 'userName', type: 'text', needSort: false},
        {name: '更换前', prop: 'carInfoBefore', type: 'text', needSort: false, style: 'flex: 1.5'},
        {name: '更换后', prop: 'carInfoAfter', type: 'text', needSort: false, style: 'min-width: 80px'},
        {name: '司机', prop: 'operatorName', type: 'text', needSort: false},
        {name: '更换时间', prop: 'carChangeTime', type: 'text', needSort: false, style: 'min-width: 180px'},
      ],
      rows: this.tableData,
      control: [
        {name: '查看详情', type: 'warning', method: this.jumpOrderInfo},
      ]
    }}})],1):_vm._e(),_c('section',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","current-page":this.pageNum,"page-sizes":[10, 20, 50, 100],"page-size":this.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":this.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)]):_c('fragment',[_c('img',{staticClass:"loading",attrs:{"src":require("../../../assets/images/loading.gif"),"height":"100","width":"100","alt":"加载中"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }